import pino from 'pino'
import { clefLevels, toClef } from '../utils/pinoClef' // relative import path to enable drizzle to generate schema

type MixinFn = (mergeObject: object, level: number) => object
let _logger: pino.Logger | null = null

export function getLogger(
  logLevel: pino.LevelWithSilent = 'info',
  pretty = false,
  mixin?: MixinFn
): pino.Logger {
  if (_logger) return _logger

  const pinoOptions: pino.LoggerOptions = {
    base: null, // Set to undefined to avoid adding pid, hostname properties to each log.
    formatters: {
      level: (label: string, number: number) => {
        // for not Pretty, we're providing clef levels
        return pretty
          ? { level: label }
          : {
              '@l':
                number in clefLevels
                  ? clefLevels[number as keyof typeof clefLevels]
                  : clefLevels[30] //info
            }
      },
      log: (logObject) => (pretty ? logObject : toClef(logObject))
    },
    messageKey: pretty ? 'msg' : '@mt',
    mixin,
    level: logLevel,
    // when not pretty we need the time in the clef appropriate field, not from pino
    timestamp: pretty ? pino.stdTimeFunctions.isoTime : false
  }

  if (pretty) {
    pinoOptions.transport = {
      target: '@speckle/shared/pinoPrettyTransport.js',
      options: {
        colorize: true,
        destination: 2, //stderr
        ignore: 'time',
        levelFirst: true,
        singleLine: true
      }
    }
  }

  _logger = pino(pinoOptions)
  return _logger
}

export function extendLoggerComponent(
  logger: pino.Logger,
  ...subComponent: string[]
): pino.Logger {
  const loggerBindings = logger.bindings()
  // bindings are a Record<str, Any> using . accessors should not be replaced with indexing
  loggerBindings['component'] = [loggerBindings['component'], ...subComponent]
    .filter(Boolean)
    .flat()
  return logger.child(loggerBindings)
}
